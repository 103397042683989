/* eslint-disable */
import {
  getUploadFileStatus,
  postUploadModels,
} from '../api/file';

import * as SparkMD5 from '../../public/static/spark-md5.min.js';

const chunkSize = 1045671;
const blobSlice = File.prototype.slice;
const spark = new SparkMD5.ArrayBuffer();

class Model {
  file: any
  modelPathArray: any
  totalChunk: number
  chunkSize: number
  bench: string
  token: string
  api: any
  componentIds: string[]
  constructor (file, token) {
      this.file = file;
      this.token = token;
      this.modelPathArray = [];
      this.totalChunk = Math.ceil(file.size / 1045671);
      this.chunkSize = 1045671;
      this.bench = '';
      this.api = null;
      this.componentIds = [];
  }
  getFileHash () {
    let tmpMd5;
    let fileReader = new FileReader();
    let _this = this;
    return new Promise((resolve, reject) => {
        fileReader.onload = function (e) {
            spark.append(e.target?.result);
            tmpMd5 = spark.end();
            // eslint-disable-next-line no-undef
            resolve(tmpMd5);
        };
        fileReader.onerror = function () {
            // console.warn('oops, something went wrong.');
        };

        function loadNext () {
            let start = 0;
            let end = _this.file.size;
            fileReader.readAsArrayBuffer(blobSlice.call(_this.file, start, end));
        }

        loadNext();
    });
  }
  async getFileStatus () {
      this.totalChunk = Math.ceil(this.file.size / chunkSize);
      let fileHash = await this.getFileHash();
      const res = await getUploadFileStatus({ hash: fileHash, totalChunks: this.totalChunk }, this.token);
      return res;
  }
  async upload (chunkFileObj, fileHash, currentChunk, source, files) {
      const res = await postUploadModels({
          'FileHash': fileHash,
          'ChunkHash': chunkFileObj.tmpMd5,
          'ChunkNumber': currentChunk,
          'TotalChunks': this.totalChunk,
          'CurrentChunkSize': chunkFileObj.blob.size,
          'FileExtention': this.file.name.substr(this.file.name.lastIndexOf('.')),
          'FileName': this.file.name,
          'FileSize': this.file.size,
          'Chunk': chunkFileObj.blob
      }, this.token);
      return res;
  }

  getChunkHash (currentChunk) {
      let _this = this;
      return new Promise(function (resolve, reject) {
          let tmpMd5;
          let blob;
          // eslint-disable-next-line no-undef
          let spark = new SparkMD5.ArrayBuffer();
          let fileReader = new FileReader();
          fileReader.onload = function (e) {
              spark.append(e.target?.result);
              tmpMd5 = spark.end();
              let chunkFileObj = {
                  blob,
                  tmpMd5
              };
              resolve(chunkFileObj);
          };
          fileReader.onerror = function () {
              console.warn('oops, something went wrong.');
          };

          function loadNext () {
              let start = currentChunk * chunkSize;
              let end = ((start + chunkSize) >= _this.file.size) ? _this.file.size : start + chunkSize;
              fileReader.readAsArrayBuffer(blobSlice.call(_this.file, start, end));
              blob = blobSlice.call(_this.file, start, end);
          }

          loadNext();
      });
  }
}

export default Model;
