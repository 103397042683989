
import Vue from 'vue';

export default Vue.extend({
  props: {
    unpassReasonVisible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
      return {
        unpassReason: '',
        presetReasons: [
          '族文件关键数据缺失或未正确写入',
          '产品信息填写不够准确完善',
        ],
      };
  },
  watch: {
    unpassReasonVisible(val) {
      console.log(val);
      this.unpassReason = '';
    },
  },
  methods: {
    editProductPass() {
      this.$emit('editProductPass', false, this.unpassReason);
    },
    cancel() {
      this.$emit('cancelDialog');
    },
  },
});

