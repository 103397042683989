
import Vue from "vue";
import * as api from "@/api";
import { ProductInfoState } from "@/store/types";

export default Vue.extend({
  props: {
    save: Boolean,
  },
  name: "SeleteClass",
  computed: {
    selectedClassify(): ProductInfoState["selectedClassify"] {
      console.log(this.$store.state.productInfoModule.selectedClassify);
      return this.$store.state.productInfoModule.selectedClassify;
    },
    productId(): string {
      return this.$store.state.productInfoModule.currentProductInfo.productId;
    },
    pvId(): string {
      return this.$store.state.productInfoModule.currentProductInfo.pvId;
    },
    historyOptions(): api.ProductTypeTreeNode[] {
      return this.$store.state.productInfoModule.historyOptions;
    },
    typeForestData(): api.ProductTypeTreeNode[] {
      return this.$store.state.productInfoModule.typeForestData;
    },
    editable(): boolean {
      return (
        this.$store.state.productInfoModule.currentProductInfo.status === 10 ||
        !!this.$store.state.productInfoModule.currentProductInfo.nextVersion
      ); // 是否为审核中或者有子级
    },
  },
  data() {
    return {
      defalutProps: { children: "children", label: "txt", disabled: (this as any).disabledFn },
      loading: true,
      selectTypeTreeId: "",
      rootId: "",
      selectedClass: [] as any,
      typeForestRoots: [],
      selectedHistory: [],
      selectedTree: [],
      selectMax: 2, // 最多选中的数量
      timer: null as any,
      rules: {
        selectType: [],
      },
    };
  },
  watch: {
    save(val) {
      val && this.saveTypes();
    },
    typeForestData: {
      handler(val) {
        if (val.length > 0 && !this.productId) {
          this.selectTypeTreeId = val[0].id;
        }
      },
      immediate: true,
    },
  },
  async created() {
    this.$store.dispatch("productInfoModule/getProductCertifications");
    this.$store.dispatch("productInfoModule/getFireRatings");
    this.$store.dispatch("productInfoModule/getIsos");
    this.$store.dispatch("productInfoModule/getProductTypeHistory");
  },
  mounted() {
    if (this.productId) {
      this.init(); // 涉及到dom的操作放到mounted中
    }
  },
  methods: {
    handleClose(item) {
      const matchIndex_selectedClass = this.selectedClass.findIndex((i) => item.type === i.type);
      const matchIndex_selectedHistory = this.selectedHistory.findIndex(
        (i: any) => item.type === i.type
      );
      if (matchIndex_selectedClass > -1) {
        this.selectedClass.splice(matchIndex_selectedClass, 1);
        (this.$refs.tree as any).forEach((element) => {
          element.setChecked(item.type, false);
        });
      } else if (matchIndex_selectedHistory > -1) {
        this.selectedHistory.splice(matchIndex_selectedHistory, 1);
      }
      this.$store.commit("productInfoModule/selectedClassify", [
        ...this.selectedClass,
        ...this.selectedHistory,
      ]);
    },
    disabledFn() {
      return this.editable;
    },
    async init() {
      this.loading = true;
      const opt = {
        pvId: this.pvId,
        productId: this.productId,
        step: 1,
      };
      const res = await api.getProductInfo(opt);
      if (!api.error(res)) {
        res.data.types.forEach((item) => {
          this.selectedClass.push({
            type: item.typeId,
            typeTxt: item.fullTxt,
          });
        });
        (this as any).selectTypeTreeId = this.typeForestData.find(
          (item) => Number(item.id) === Number(res.data.types[0].typeId) % 100
        )?.id;
        const selectTreeIndex: number = this.typeForestData.findIndex(
          (item) => Number(item.id) === Number(res.data.types[0].typeId) % 100
        );
        this.loading = false;
        setTimeout(() => {
          const list = res.data.types.map((e) => {
            (this.$refs.tree![selectTreeIndex] as any).setChecked(e.typeId, true, true);
            return {
              type: e.typeId,
              typeTxt: e.fullTxt,
            };
          });
          this.$store.commit("productInfoModule/selectedClassify", list);
          setTimeout(() => {
            let unit = 0;
            const selectCheckbox: any = document.querySelector(
              `.tree${selectTreeIndex} .el-tree-node .is-checked`
            );
            const offsetTopTree = document.querySelector(`.tree${selectTreeIndex}`) as any;
            this.timer = setInterval(() => {
              if (offsetTopTree.scrollTop < selectCheckbox.offsetTop - 380) {
                unit += 100;
                (document.querySelector(`.tree${selectTreeIndex}`) as any).scrollTop = unit;
              } else {
                clearInterval(this.timer);
              }
            }, 1000);
          }, 400);
        }, 400);
      }
    },
    async saveTypes() {
      const ops = {
        productId: this.productId,
        pvId: this.pvId,
        types: this.selectedClassify.map((e) => e.type),
      };
      const res = await api.postTypes(ops);
      if (!api.error(res)) {
        this.$store.commit("productInfoModule/currentProductInfo", {
          productId: res.data.productId,
          pvId: res.data.pvId,
          status:
            this.$route.path === "/product/add"
              ? "0"
              : this.$store.state.productInfoModule.currentProductInfo.status,
          statusTxt:
            this.$route.path === "/product/add"
              ? "草稿"
              : this.$store.state.productInfoModule.currentProductInfo.statusTxt,
        });
        this.$emit("saveInfo", true);
      } else {
        this.$message.error(res.error.message);
      }
      this.$emit("endSave");
    },
    changeHistory() {
      this.$store.commit("productInfoModule/selectedClassify", [
        ...this.selectedClass,
        ...this.selectedHistory,
      ]);
    },
    handleTypeTreeCheck(v1) {
      const selectedClass: any = [];
      (this.$refs.tree as any).forEach((element) => {
        element.getCheckedNodes(true).forEach((item) => {
          selectedClass.push({
            type: item.id,
            typeTxt: item.fullTxt,
          });
          if (selectedClass.length + this.selectedHistory.length > 2) {
            (this as any).$message({
              type: "info",
              message: `最多可选${this.selectMax}个`,
            });
            selectedClass.splice(
              selectedClass.findIndex((i) => i.type === v1.id),
              1
            );
          }
          const checkedKeysLeaf = selectedClass.map((checked) => checked.type);
          element.setCheckedKeys(checkedKeysLeaf);
          this.$store.commit("productInfoModule/selectedClassify", [
            ...selectedClass,
            ...this.selectedHistory,
          ]);
        });
      });
      this.$nextTick(() => {
        this.selectedClass = selectedClass;
      });
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
});
