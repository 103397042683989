
import Vue from 'vue';

export default Vue.extend({
  name: 'el-tree-select',
  props: {
    /** 是否禁用 */
    disabled: {
      type: Boolean,
      default: false,
    },
    /* 配置项 */
    treeProps: {
      type: Object,
    },
    /* 选项列表数据(树形结构的对象数组) */
    options: {
      type: Array,
    },
    /* 初始值 */
    value: {
      type: Number,
      default: null,
    },
    /* 可清空选项 */
    clearable: {
      type: Boolean,
      default: true,
    },
    /* 自动收起 */
    accordion: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '全部分类',
    },
  },
  data() {
    return {
      valueId: this.value, // 初始值
      valueTitle: '',
      defaultExpandedKey: [] as number[],
    };
  },
  mounted() {
    this.initHandle();
  },
  watch: {
    value() {
      this.valueId = this.value;
      this.initHandle();
    },
  },
  methods: {
    // 初始化值
    initHandle() {
      if (this.valueId) {
        this.valueTitle = (this.$refs.selectTree as any).getNode(this.valueId).data[this.treeProps.label]; // 初始化显示
        (this.$refs.selectTree as any).setCurrentKey(this.valueId); // 设置默认选中
        this.defaultExpandedKey = [this.valueId]; // 设置默认展开
      }
    },
    // 切换选项
    handleNodeClick(node) {
      if (this.disabled) return;
      this.valueTitle = node[this.treeProps.label];
      this.valueId = node[this.treeProps.value];
      this.$emit('getValue', this.valueId);
      this.defaultExpandedKey = [];
      if (!node.children || !node.children.length) { // 补录选择完选项不能收起
        (this.$refs.treeSelect as any).blur();
      }
    },
    // 清除选中
    clearHandle() {
      this.valueTitle = '';
      this.valueId = -1;
      this.defaultExpandedKey = [];
      this.clearSelected();
      this.$emit('getValue', null);
    },
    /* 清空选中样式 */
    clearSelected() {
      const allNode = document.querySelectorAll('#tree-option .el-tree-node');
      allNode.forEach((element) => element.classList.remove('is-current'));
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
  },
});
