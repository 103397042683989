
  import Vue from 'vue';
  import * as api from '@/api';
  import ProductModelUpload from './ProductModelUpload.vue';

  const { spdengine } = (window as any);

  export default Vue.extend({
    components: {
      ProductModelUpload,
    },
    props: {
      step: Number,
    },
    data() {
      return {
        headers: {
          Authorization: '',
        },
        properties: [] as api.ProductProperties[],
        location: window.location.origin,
        imgUploadUrl: `${process.env.VUE_APP_FILEURL}api/v1.0/File`,
        imgList: [] as api.ProductImages[],
        bench: 0,
        loading: false,
        token: '',
        modelGroupId: '',
        modelPath: 'SpiderView/ee053579-e824-4a1a-8b69-3f101cb543b1/00000000-0000-0000-0000-000000000000/f4767fe3c70f06e83f4d156a7233ef78/9/',
        timer: 0,
        replaceModel: false,
        convertFailed: false,
        infos: [
          {
            name: 'postFilesModels',
            isBasic: true,
            list: [] as api.ProductBasicModel[],
            tip: '支持单个.rfa或者.rvt格式，不超过100MB的文件上传',
            accept: '.rfa,.rvt',
            subTip: '系统会依据产品类型进行数据标准化审查，请确保您已在族文件内填写相关的标准化数据',
          },
          {
            name: 'postFilesModels',
            isBasic: false,
            list: [] as api.ProductBasicModel[],
            tip: '支持单个或多个.ifc, .obj, .skp, .stl, .dwg, .fbx, .rvt, .3ds格式，单个不超过100MB的文件上传',
            accept: '.ifc,.obj,.skp,.stl,.dwg,.fbx,.rvt,.3ds',
          },
          {
            name: 'postFilesDrawings',
            list: [] as api.ProductBasicModel[],
            tip: '支持单个.dwg格式，不超过20MB的文件上传',
            accept: '.dwg',
          },
        ],
      };
    },
    computed: {
      productId(): string {
        return this.$store.state.productInfoModule.currentProductInfo.productId;
      },
      pvId(): string {
        return this.$store.state.productInfoModule.currentProductInfo.pvId;
      },
      editable(): boolean {
        return this.$store.state.productInfoModule.currentProductInfo.status === 10 || !!this.$store.state.productInfoModule.currentProductInfo.nextVersion; // 是否为审核中或者有子级
      },
    },
    async mounted() {
      console.log(process.env.VUE_APP_FILEURL);
      await this.$store.dispatch('getToken');
      this.headers.Authorization = this.$store.state.fileToken;
      this.token = this.$store.state.fileToken;
      this.getInfos(true);
    },
    methods: {
      async getInfos(init) { // 获取信息
        const opt = {
          pvId: this.pvId,
          productId: this.productId,
          step: this.step,
        };
        const res = await api.getProductInfo(opt);
        if (!api.error(res)) {
          if (init && res.data.images.length > 0) this.imgList = res.data.images;
          if (res.data.basicModel && res.data.basicModel.url) {
            this.modelGroupId = res.data.basicModel.modelGroupId;
            this.modelPath = res.data.basicModel.url;
            this.loading = true;
            this.destroyed();
            this.creatModel('model');
            setTimeout(() => {
              this.creatModel('hideModel');
            }, 1000);
            clearInterval(this.timer);
            this.properties = res.data.properties;
          }
          this.convertFailed = !!(res.data.basicModel && res.data.basicModel.convertFailed);
          if (res.data.basicModel && res.data.basicModel.convertFailed) { // 转化失败
            this.loading = true;
            clearInterval(this.timer);
          }
          if (init) {
            if (res.data.basicModel) {
              this.infos[0].list = [res.data.basicModel];
              this.infos[0].list[0].name = res.data.basicModel.fileName;
            }
            if (res.data.extendedModels.length > 0) {
              this.infos[1].list = res.data.extendedModels;
              this.infos[1].list.forEach((e) => {
                e.name = e.fileName;
              });
            }
            if (res.data.drawings) {
              this.infos[2].list = [res.data.drawings];
              this.infos[2].list[0].name = res.data.drawings.fileName;
            }
          }
        }
      },
      // 选取文件超过数量提示
      beforeUpload(file) {
        const format = ['image/gif', 'image/png', 'image/jpeg'];
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (this.imgList.length === 5) {
          this.$message.warning('支持最多五个文件');
        }
        if (!format.includes(file.type)) {
          this.$message.warning('只支持.jpg .png .gif格式');
        }
        if (!isLt2M) {
          this.$message.error('上传图片大小不能超过 2MB!');
        }
        return format.includes(file.type) && this.imgList.length < 5 && isLt2M;
      },
      async handleMasterPicture(file, isSnapshot?: boolean) { // 设为主图模式
        const opt = {
          productId: this.productId,
          pvId: this.pvId,
          fileId: file.fileId,
          isSnapshot: isSnapshot || false,
        };
        const res = await api.putPrimaryImages(opt);
        if (!api.error(res)) {
          const cancelIdx = this.imgList.findIndex((i) => i.isPrimary);
          if (cancelIdx !== -1) this.imgList[cancelIdx].isPrimary = false;
          const idx = this.imgList.findIndex((i) => i.fileId === file.fileId);
          this.imgList[idx].isPrimary = true;
        } else {
          this.$message.error('设为主图失败');
        }
      },
      async handleDelete(file) { // 删除图片
        await api.delFiles({ id: file.fileId });
        const idx = this.imgList.findIndex((i) => i.fileId === file.fileId);
        this.imgList.splice(idx, 1);
      },
      modelInfo(del) {
        if (del) {
          const isPrimary = this.imgList.find((i) => i.isPrimary);
          isPrimary && this.handleDelete(isPrimary);
          this.infos[0].list = [];
          this.properties = [];
          this.destroyed();
          if (this.loading) {
            clearInterval(this.timer);
          }
          this.loading = false;
          return;
        }
        this.loading = true;
        this.replaceModel = true;
        this.timer = setInterval(() => {
          this.getInfos(false);
        }, 5000);
      },
      creatModel(model) {
        const self = this;
        const container = document.getElementById(model);
        this.bench = spdengine.Scott.create({
          container,
          projectId: self.modelGroupId,
          dock: 4,
          tooBarState: false,
          // eslint-disable-next-line no-undef
          system: spdengine.SystemType.ST_3D_WSM,
          engineSrcPath: '/static/',
          alpha: true,
          envInitSuccessCB: () => {
            spdengine.Scott.run(self.bench);
            // eslint-disable-next-line no-undef
            spdengine.Scott.load(self.bench, {
              url: process.env.VUE_APP_FILEURL + self.modelPath,
              // eslint-disable-next-line no-restricted-globals
              workerUrl: `${location.origin}/static/`,
              orgModelId: 1,
              newModelId: 1,
              modelMatrix: new spdengine.Matrix43D(),
            });
            const api3D = spdengine.Apis.getApi(self.bench)['3D'];
            api3D.addEventListener('fileloader_geomallloadend', () => { // 整个模型都加载完成之后会触发的事件
              if ((self.replaceModel || !self.imgList.find((i) => i.isPrimary)) && model === 'hideModel') {
                api3D.addEventListener('renderfinish', self.uploadPicture);
              }
            });
            api3D.addEventListener('fileloader_geomloadend', () => { // 模型的一个文件加载完成之后会触发的事件
                 spdengine.Apis.getApi(self.bench).UI.setMenuState(false); // 关闭菜单
            });
            const { PARAMS } = spdengine.Apis.getApi(self.bench);
            PARAMS.spd_logoEnable = false; // 关闭logo
            PARAMS.df_enable = false; // 禁止双击
            PARAMS.ng_enable = false; // 关闭导航仪
            PARAMS.sr_enable = false; // 去掉阴影
            PARAMS.eg_clearColor = 0xffffffff; // 背景色
          },
        });
      },
      async uploadPicture() {
        spdengine.Apis.getApi(this.bench)['3D'].removeEventListener('renderfinish', this.uploadPicture);
        const can = document.querySelector('#hideModel canvas');
        const dataURLtoFile = (dataurl, filename) => {
          const arr = dataurl.split(',');
          const mime = arr[0].match(/:(.*?);/)[1];
          const bstr = atob(arr[1]);
          let n = bstr.length;
          const u8arr = new Uint8Array(n);
          while (n) {
            n -= 1;
            u8arr[n] = bstr.charCodeAt(n);
          }
          return new File([u8arr], filename, { type: mime });
        };

        const upload = async (image) => {
          const imgFile = dataURLtoFile(image, 'img.png');
          const res = await api.postUploadPicture({ file: imgFile }, this.token);
          if (!api.error(res)) {
            if (this.imgList.length === 5) { // 添加超过限制时
              const isPrimary = this.imgList.find((i) => i.isPrimary);
              isPrimary && this.handleDelete(isPrimary); // 新渲染图为主图所以要先把旧的删掉
            }
            this.$forceUpdate();
            this.handleAvatarSuccess(res, true);
          }
        };

        setTimeout(() => { // canvas绘制较慢，延时操作才能截取到完整的图片
          upload((can as any).toDataURL('image/jpeg', 0.8));
        }, 500);
      },
      async handleAvatarSuccess(res, replace) {
        if (res.success) {
          const opt = {
            pvId: this.pvId,
            files: [{
              name: res.items[0].name,
              url: res.items[0].url,
              size: res.items[0].size,
            }],
          };
          const result: any = await api.postImages(opt);
          if (!api.error(res)) {
            if (typeof (replace) === 'object') {
              replace.fileId = result.data[0].fileId;
              const hasPrimary = this.imgList.every((i) => !i.isPrimary);
              replace.isPrimary = hasPrimary;
              this.imgList.push(replace);
              if (hasPrimary) this.handleMasterPicture(replace);
            } else { // 自动截取
              const img = {
                fileName: res.items[0].name,
                url: opt.files[0].url.indexOf('http') > -1 ? opt.files[0].url : (process.env.VUE_APP_FILEURL) + opt.files[0].url,
                isPrimary: false,
                fileId: result.data[0].fileId,
              };
              this.imgList.unshift(img);
              this.handleMasterPicture(img, true);
            }
            console.log(this.imgList);
          }
        }
      },
      destroyed() {
        if (this.bench > 0) {
          spdengine.Scott.destroy(this.bench);
          spdengine.Scott.destroy(this.bench - 1);
        }
      },
    },
    destroyed() {
      clearInterval(this.timer);
      this.destroyed();
    },
  });

