
import Vue from 'vue';
import FileServer from '@/utils/model';
import * as api from '@/api';

export default Vue.extend({
  props: {
    info: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    token: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      headerDiy: {
        Authorization: '',
      },
      uploadUrl: window.location.origin,
      fileList: [] as api.ModelUpload[],
      list: [],
    };
  },
  watch: {
    'info.list': function (list) {
      this.list = JSON.parse(JSON.stringify(list));
    },
  },
  methods: {
    async handleAvatarSuccess(response, file, fileList) {
      console.log(response, file, fileList);
      if ((this.info.accept === '.rfa' || this.info.accept === '.rvt' || this.info.name === 'postFilesDrawings') && fileList.length > 1) {
        await api.delFiles({ id: fileList[0].fileId });
        fileList.splice(0, 1);
      }
      this.list = fileList;
      this.upload();
    },
    async handleRemove(file, fileList) {
      if (!file.fileId) return;
      this.list = fileList;
      const res = await api.delFiles({ id: file.fileId });
      if (res) this.$message({ type: 'success', message: '删除成功' });
      this.info.accept === '.rfa' || this.info.accept === '.rvt' && this.$emit('modelInfo', true);
    },
    // 选取文件提示
    beforeUpload(file) {
      let val = true;
      if (this.info.name === 'postTechDocs') { // 文档
        val = this.docBeforeUpload(file);
      } else if (this.info.name === 'postFilesModels' && this.info.isBasic) { // 基础族
        val = this.basicModelBeforeUpload(file);
      } else if (this.info.name === 'postFilesModels' && !this.info.isBasic) { // 扩展族
        val = this.extendModelBeforeUpload(file);
      } else if (this.info.name === 'postFilesDrawings') { // 图纸
        val = this.dwgBeforeUpload(file);
      }
      return val;
    },
    dwgBeforeUpload(file) { // 图纸
      const testmsg = file.name.substring(file.name.lastIndexOf('.') + 1);
      const extension = testmsg === 'dwg';
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!extension) {
        this.$message.warning('只支持.dwg格式');
        return false;
      }
      if (!isLt20M) {
        this.$message.error('上传文件大小不能超过 20MB!');
      }
      return extension && isLt20M;
    },
    extendModelBeforeUpload(file) { // 扩展族
      const testmsg = file.name.substring(file.name.lastIndexOf('.') + 1);
      const format = ['ifc', 'obj', 'skp', 'stl', 'dwg', 'fbx', 'rvt', '3ds'];
      const extension = format.includes(testmsg);
        const size = file.size / 1024 / 1024 < 100;
        if (!extension) {
          this.$message.warning('只支持.ifc,.obj,.skp,.stl,.dwg,.fbx,.rvt,.3ds格式');
          return false;
        }
        if (!size) {
          this.$message.error('上传文件大小不能超过 100MB!');
        }
        return extension && size;
    },
    basicModelBeforeUpload(file) { // 基础族
      const testmsg = file.name.substring(file.name.lastIndexOf('.') + 1);
      const extension = testmsg === 'rfa' || testmsg === 'rvt';
      const size = file.size / 1024 / 1024 < 100;
      if (!extension) {
        this.$message.warning('只支持.rfa和.rvt格式');
        return false;
      }
      if (!size) {
        this.$message.error('上传文件大小不能超过 100MB!');
      }
      return extension && size;
    },
    docBeforeUpload(file) { // 文档
      const testmsg = file.name.substring(file.name.lastIndexOf('.') + 1);
      const extension = testmsg === 'doc'
        || testmsg === 'docx'
        || testmsg === 'pdf';
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (this.info.list.length === 5) {
          this.$message.warning('支持最多五个文件');
          return false;
        }
        if (!extension) {
          this.$message.warning('只支持.doc .docx .pdf格式');
          return false;
        }
        if (!isLt5M) {
          this.$message.error('上传文件大小不能超过 5MB!');
        }
        return extension && isLt5M;
    },
    async myUpload() {
      // 阻止element上传
    },
    upload() {
      this.list.forEach(async (element) => {
              if ((element as any).docs) return;
              const self = this;
              // eslint-disable-next-line no-inner-declarations
              async function upload(i, total, unFinish?, unUploadChunksArray?, unUploadChunksI?) {
                  // eslint-disable-next-line @typescript-eslint/no-use-before-define
                  const chunkFileObj = await fileServer.getChunkHash(i);
                  // eslint-disable-next-line @typescript-eslint/no-use-before-define
                  const data = fileServer.upload(chunkFileObj, fileHash, i, 1, []);
                  data.then((res: any) => {
                      if (res.success) {
                          if (unFinish) {
                              if (unUploadChunksI < total - 1) {
                                  unUploadChunksI += 1;
                                  upload(unUploadChunksArray[unUploadChunksI], total, 1, unUploadChunksArray, unUploadChunksI);
                              }
                          } else if (i < total - 1) {
                              i += 1;
                              upload(i, total);
                          }
                          if (res.item) {
                            self.updateList(res.item, element);
                          }
                      } else {
                         // '文件上传失败,请重新上传';
                      }
                      self.$forceUpdate();
                  });
              }

              let fileServer = new FileServer((element as any).raw, this.token);
              const fileStatus = fileServer.getFileStatus();
              let fileHash = await fileServer.getFileHash();
              // eslint-disable-next-line no-shadow
              fileStatus.then(async (fileStatus) => {
                  const { status } = (fileStatus as any).item;
                  if (status === 2) { // 文件已经上传
                      console.log(fileStatus);
                      self.updateList((fileStatus as any).item.file, element);
                      this.$forceUpdate();
                  } else if (status === 0) { // 文件未上传
                      const i = 0;
                      upload(i, fileServer.totalChunk);
                  } else { // 文件部分上传
                      const i = 0;
                      const unUploadChunksArray = (fileStatus as any).item.unUploadChunks;
                      upload(unUploadChunksArray[0], unUploadChunksArray.length, 1, unUploadChunksArray, i);
                  }
              });
      });
    },
    async updateList(file, element) {
      if (this.info.name === 'postTechDocs') {
        this.docs(file);
        return;
      }
       const fileList: api.ModelUpload[] = [{
        pvId: (this.$store as any).state.productInfoModule.currentProductInfo.pvId,
        name: file.name,
        size: file.size,
        url: file.url,
        viewFileId: file.id,
      }];
      if (this.info.name !== 'postFilesDrawings') {
        fileList[0].isBasic = this.info.isBasic;
      }
      const res = await api[this.info.name](fileList);
      element.fileId = res.data[0].fileId;
      (this.info.accept === '.rfa' || this.info.accept === '.rvt') && this.$emit('modelInfo');
    },
    async docs(file) { // 技术文档上传
      (this.list[this.list.length - 1] as any).docs = {
        name: file.name,
        size: file.size,
        url: file.url,
        viewFileId: file.id,
        cover: '',
      };
      this.$emit('docsInfo', this.list);
    },
  },
});

