
import Vue from "vue";
import * as api from "@/api";
import AuditDetail from "@/components/AuditDetail.vue";
import SelectClass from "./SelectClass.vue";
import ProductInfo from "./ProductInfo.vue";
import ProductProperty from "./ProductProperty.vue";
import ProductRelevance from "./ProductRelevance.vue";
import AuditDialog from "../audit/AuditDialog.vue";

export default Vue.extend({
  name: "addproduct",
  components: {
    SelectClass,
    ProductInfo,
    ProductProperty,
    ProductRelevance,
    AuditDetail,
    AuditDialog,
  },
  computed: {
    currentProductInfo() {
      return this.$store.state.productInfoModule.currentProductInfo;
    },
  },
  data() {
    return {
      selectedProducts: [] as api.Product[],
      unpassReasonVisible: false,
      presetReasons: ["族文件关键数据缺失或未正确写入", "产品信息填写不够准确完善"],
      auditDetailVisible: false,
      handleAudit: false,
      handleNext: false,
      step: 0,
      save: false,
      content: "SelectClass",
      steps: [
        { label: "选择分类", content: "SelectClass" },
        { label: "填写信息", content: "ProductInfo" },
        { label: "填写属性", content: "ProductProperty" },
        { label: "关联产品", content: "ProductRelevance" },
      ],
    };
  },
  created() {
    this.$store.commit("breadcrumbList", [
      {
        name: this.$route.meta!.activeMenu === "/product/list" ? "产品列表" : "产品审核",
        path: this.$route.meta!.activeMenu,
      },
      {
        name:
          Object.keys(this.currentProductInfo).length > 0
            ? this.currentProductInfo.name
            : "添加产品",
        path: "add",
      },
    ]);
  },
  methods: {
    async editProductPass(value: boolean, unpassReason?: string) {
      // 审核
      const productIds = [this.currentProductInfo.productId];
      const res = value
        ? await api.putProductAuditPass({ productIds })
        : await api.putProductAuditUnpass({ productIds, reason: unpassReason as string });
      if (!api.error(res)) {
        this.$message({
          type: "success",
          message: "操作成功",
        });
        this.unpassReasonVisible = false;
        this.$router.push({ path: "/product/audit" });
      } else {
        this.$message({
          type: "error",
          message: res.error.message,
        });
      }
    },
    getStatusColor(status) {
      const map = {
        0: "color-info",
        10: "color-warning",
        20: "color-error",
        30: "color-success",
      };
      return map[status];
    },
    currentStep(val) {
      if (!this.currentProductInfo.pvId) return;
      this.step = val - 1;
    },
    showAuditDetail() {
      // todo: 传参
      this.auditDetailVisible = true;
    },
    cancel() {
      if (this.step === 1 || this.step === 2) {
        (this as any)
          .$confirm("确定将不会保存当前产品的信息", "你确定要取消？", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            customClass: "messageError",
            iconClass: "el-icon-question iconRed",
          })
          .then(async () => {
            this.$router.push({ path: "/product/list" });
          });
      } else {
        this.$router.push({ path: "/product/list" });
      }
    },
    handleSave() {
      if (this.step === 2) {
        // 填写属性是即时保存所以点击保存就只弹个信息就好
        this.saveInfo(true);
      } else {
        this.save = true;
      }
    },
    next() {
      if ((this.step === 0 && !this.currentProductInfo.pvId) || this.step === 2) {
        this.step += 1;
        return;
      }
      this.handleNext = true;
      this.handleSave();
    },
    async saveInfo(val) {
      if (val && this.handleNext) {
        this.handleNext = false;
        this.step += 1;
      } else if (val && this.handleAudit) {
        const opt = { pvId: this.currentProductInfo.pvId };
        const res = await api.postAudit(opt);
        if (res) {
          this.$message.success("提交审核成功");
          this.$router.push({ path: "/product/list" });
        } else {
          this.$message.error("提交审核失败");
        }
        this.handleAudit = false;
      } else if (val && !this.handleAudit) this.$message.success("保存成功");
    },
    audit() {
      // 提交审核
      this.handleAudit = true;
      this.handleSave();
    },
  },
});
