
  import Vue from 'vue';
  import * as api from '@/api';
  import SelectTree from '../../../components/TreeSelect.vue';

  interface ProductOptions {
      key: string;
      label: string;
      productId: string;
      pvId: string;
      name: string;
  }
  export default Vue.extend({
    components: {
      SelectTree,
    },
    props: {
      step: Number,
      save: Boolean,
    },
    data() {
      return {
        props: {
          value: 'id', // ID字段名
          label: 'txt', // 显示名称
          children: 'children', // 子级字段名
        },
        showList: [
          {
            pvId: 3,
          },
        ],
        isClearable: true, // 可清空（可选）
        isAccordion: true, // 可收起（可选）
        typeId: null, // 初始ID（可选）
        isTwoway: false,
        typeTree: [] as api.ProductInfoTypeTreeNode[],
        brandOptions: [] as api.BrandOptions[],
        brandId: null as number | null,
        list: [] as ProductOptions[],
        value2: [] as string[],
        selected: [] as any[],
        next: true,
        filterMethod(query, item) {
          return item.label.indexOf(query) > -1;
        },
      };
    },
    mounted() {
      this.getInfos();
    },
    watch: {
      brandId() {
        this.list = [];
        this.getList();
      },
      save(val) {
        val && this.saveVal();
      },
      value2(selected) {
        const filterId = [...selected];
        const old = this.selected.filter((e) => { // 已选列表
          const idx = selected.findIndex((i) => i === e.pvId);
          idx > -1 && filterId.splice(idx, 1);
          return e.pvId === selected[idx];
        });
        const newSelected = this.list.filter((e) => { // 已选列表
          const val = filterId.find((i) => i === e.pvId);
          return e.pvId === val;
        });
        this.selected = [...old, ...newSelected];
      },
    },
    computed: {
      productId(): string {
        return this.$store.state.productInfoModule.currentProductInfo.productId;
      },
      editable(): boolean {
        return this.$store.state.productInfoModule.currentProductInfo.status === 10 || !!this.$store.state.productInfoModule.currentProductInfo.nextVersion; // 是否为审核中或者有子级
      },
    },
    methods: {
      async getInfos() { // 获取信息
        const opt = {
          pvId: this.$store.state.productInfoModule.currentProductInfo.pvId,
          productId: this.productId,
          step: this.step,
        };
        const res = await api.getProductInfo(opt);
        if (!api.error(res)) {
          this.selected = res.data.correlations.map((item) => ({
              ...item,
              key: item.pvId,
              label: item.name,
            }));
          this.value2 = res.data.correlations.map((i) => i.pvId);
          this.isTwoway = res.data.correlationMode === '1';
          this.getList();
          this.getTypeTree();
          this.getBrand();
        }
      },
      // 取值
      getValue(value) {
        this.typeId = value;
        this.list = [];
        this.getList();
      },
      async getList() { // 产品列表
        const opt = {
          type: this.typeId,
          brandId: this.brandId,
          excludeProductIds: this.productId as string,
          skipCount: this.list.length,
          maxResultCount: this.value2.length + 50,
        };
        const res = await api.getProductOptions(opt);
        if (!api.error(res)) {
          let list = res.data.items.map((item) => ({
              ...item,
              key: item.pvId,
              label: item.name,
            }));
          const m = new Map();
          list = [...this.list, ...list].filter((a) => !m.has(a.pvId) && m.set(a.pvId, 1)); // 如果加载出之前添加的就去重
          let selected: any = [];
          // todo: 已选的值根据左边列表改变，左边列表没有的话就要把选中的添加进列表，不然已选的值出不来
          if (this.selected.length > 0) {
            selected = this.selected.filter((e) => list.every((i) => i.pvId !== e.pvId));
          }
          this.list = [...selected, ...list];
          if (res.data.items.length === 0) {
            this.next = false;
            return;
          }
          const ul: any = document.querySelector('.el-transfer-panel__list');
          this.$nextTick(() => {
            ul.addEventListener('scroll', this.scrollEvent);
          });
        }
      },
      scrollEvent() {
        const list: any = document.querySelector('.el-transfer-panel__list');
        if (list.scrollHeight - list.scrollTop === list.clientHeight) {
          this.next && this.getList();
        }
      },
      async getTypeTree() { // 全部分类
        const res = await api.getProductInfoTypeTree();
        console.log(res);
        if (!api.error(res)) {
          this.typeTree = res.data.roots;
        }
      },
      async getBrand() { // 全部品牌
        const res = await api.getBrandOptions({});
        if (!api.error(res)) {
          this.brandOptions = res.data.items;
          if (this.brandOptions.length === 1) this.brandId = this.brandOptions[0].brandId;
        }
      },
      saveVal() {
        const products = this.list.filter((e) => {
          const val = this.value2.find((i) => i === e.pvId);
          return e.pvId === val;
        }).map((r) => ({
          productId: r.productId,
          pvId: r.pvId,
        }));
        this.putList(products);
      },
      async putList(products) {
        const opt = {
          id: this.productId,
          isTwoway: this.isTwoway,
          products,
        };
        const res = await api.putProductCorrelations(opt);
        if (!api.error(res)) {
          this.$emit('saveInfo', true);
        } else {
          this.$emit('saveInfo', false);
        }
        this.$emit('endSave');
      },
    },
  });

