var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"addProduction"},[_c('div',{staticClass:"addproduct-header"},[(Object.keys(_vm.currentProductInfo).length > 0)?_c('div',{staticClass:"tag"},[_c('p',{class:_vm.getStatusColor(_vm.currentProductInfo.status)},[_vm._v(_vm._s(_vm.currentProductInfo.statusTxt))]),_c('i',{staticClass:"el-icon-info",staticStyle:{"cursor":"pointer"},on:{"click":_vm.showAuditDetail}})]):_vm._e(),_c('div',{staticClass:"addproduct-header__step"},[_c('z-step-header',[_vm._l((_vm.steps),function(item,idx){return [_c('z-step',{key:idx,attrs:{"step":(idx + 1).toString(),"label":item.label,"bgColor":_vm.step >= idx ? '#02d6dc' : '',"borderColor":_vm.step >= idx ? '#02d6dc' : '#999',"color":_vm.step >= idx ? '#fff' : '#999',"txtColor":_vm.step === idx ? '#02d6dc' : !_vm.currentProductInfo.pvId ? '#999' : '#333'},on:{"currentStep":_vm.currentStep}}),(idx < 3)?_c('z-divider',{key:item.content,attrs:{"borderColor":_vm.step - 1 >= idx ? '#02d6dc' : 'gray'}}):_vm._e()]})],2)],1)]),_c('div',{staticClass:"addProduction-content__warp"},[_c('div',{staticClass:"addProduction-content__main"},[_c(_vm.steps[_vm.step].content,{tag:"component",attrs:{"step":_vm.step + 1,"save":_vm.save},on:{"endSave":function($event){_vm.save = false},"saveInfo":_vm.saveInfo}})],1)]),_c('el-drawer',{attrs:{"title":"审核详情","size":"300px","visible":_vm.auditDetailVisible,"direction":"rtl"},on:{"update:visible":function($event){_vm.auditDetailVisible=$event}}},[_c('AuditDetail',{attrs:{"pvId":_vm.currentProductInfo.pvId}})],1),(
      _vm.currentProductInfo.status !== 10 &&
      !_vm.currentProductInfo.nextVersion &&
      !_vm.currentProductInfo.readonly &&
      _vm.$route.meta.activeMenu === '/product/list'
    )?_c('div',{staticClass:"operation-footer"},[_c('div',[_c('div',[_c('el-button',{staticClass:"left_btn",on:{"click":_vm.cancel}},[_vm._v("取消")]),(_vm.step > 0 || _vm.currentProductInfo.pvId)?_c('el-button',{staticClass:"left_btn",on:{"click":_vm.handleSave}},[_vm._v("保存")]):_vm._e()],1),_c('div',[(_vm.step > 0)?_c('el-button',{attrs:{"type":"default"},on:{"click":function($event){_vm.step--}}},[_vm._v("上一步")]):_vm._e(),(_vm.step < 3)?_c('el-button',{attrs:{"type":"primary","disabled":_vm.$store.state.productInfoModule.selectedClassify.length === 0},on:{"click":_vm.next}},[_vm._v("下一步")]):_vm._e(),(
            _vm.step === 3 || (_vm.currentProductInfo.pvId && _vm.currentProductInfo.statusTxt !== '草稿')
          )?_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.audit}},[_vm._v("提交审核")]):_vm._e()],1)])]):_vm._e(),(_vm.$route.meta.activeMenu === '/product/audit')?_c('div',{staticClass:"operation-footer"},[_c('div',[_c('div'),_c('div',[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.editProductPass(true)}}},[_vm._v("通过")]),_c('el-button',{attrs:{"type":"danger"},on:{"click":function($event){_vm.unpassReasonVisible = true}}},[_vm._v("不通过")])],1)])]):_vm._e(),_c('audit-dialog',{attrs:{"unpassReasonVisible":_vm.unpassReasonVisible},on:{"editProductPass":_vm.editProductPass,"cancelDialog":function($event){_vm.unpassReasonVisible = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }