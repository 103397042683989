
  import Vue from 'vue';
  import { EnumInfo } from '@/api/types';

  import { ProductInfoState } from '@/store/types';
  import { ElForm } from 'element-ui/types/form';
  import * as api from '@/api';
  import ProductModelUpload from './ProductModelUpload.vue';

  interface Docs {
    docs: api.TechDocs;
    name: string;
  }
  export default Vue.extend({
    props: {
      save: Boolean,
      step: Number,
    },
    components: {
      ProductModelUpload,
    },
    data() {
      const cityOptions = [
        ['建筑', '柱', '柱'],
        ['建筑', '给排水', '消防系统', '灭火器'],
      ];
      return {
        headers: {
          Authorization: '',
        },
        imgUploadUrl: `${process.env.VUE_APP_FILEURL}api/v1.0/File`,
        info: {
          name: 'postTechDocs',
          list: [] as Docs[],
          hideFile: true,
          tip: '支持最多5个.doc .docx .pdf格式，单个不能超过5MB的文件上传',
          accept: '.doc,.docx,.pdf',
        },
        cities: cityOptions,
        checkboxGroup1: [],
        ruleForm: {
          category: {} as any, // 类型
          name: '', // 产品名称
          modelNumber: '', // 型号
          brandId: '', // 品牌
          description: '', // 产品描述
          Cert_BuildingMaterials: [], // 建材认证
          fireRating: '', // 防火等级
          iso: [],
        },
        productCategory: '',
        product: {
          name: '',
          brandId: null,
          modelNumber: '',
          category: '',
          description: '',
          types: [] as string[],
        } as api.ProductInfo,
        certAndRating: {
          bm: [] as string[] | string,
          fr: '',
          iso: [] as string[] | string,
          sf: '',
        },
        rules: {
          category: [
            { required: true, message: '请选择类型', trigger: 'change' },
          ],
          name: [
            { required: true, message: '请输入产品名称', trigger: 'blur' },
            // { pattern: /^[\u4E00-\u9FA5A-Za-z0-9_]+$/, message: '不允许特殊字符', trigger: 'blur' },
          ],
          modelNumber: [
            { required: true, message: '请输入产品型号', trigger: 'blur' },
            // { pattern: /^[\u4E00-\u9FA5A-Za-z0-9_]+$/, message: '不允许特殊字符', trigger: 'blur' },
          ],
          brandId: [
            { required: true, message: '请选择品牌', trigger: 'change' },
          ],
          description: [
            { required: true, message: '请填写产品描述', trigger: 'blur' },
          ],
        },
      };
    },
    watch: {
      save(val) {
        val && (this as any).submitBaseInfo('ruleForm1');
      },
      brandOptions(val) {
        if (val.items.length === 1 && !this.productId) { // 品牌列表选择只有一个时默认第一个
          this.product.brandId = this.brandOptions.items[0].brandId;
        }
      },
    },
    computed: {
      editable(): boolean {
        return this.$store.state.productInfoModule.currentProductInfo.status === 10 || !!this.$store.state.productInfoModule.currentProductInfo.nextVersion; // 是否为审核中或者有子级
      },
      selectedClassify(): ProductInfoState['selectedClassify'] {
        return this.$store.state.productInfoModule.selectedClassify;
      },
      categoryOptions(): ProductInfoState['categoryOptions'] {
        return this.$store.state.productInfoModule.categoryOptions;
      },
      brandOptions(): ProductInfoState['brandOptions'] {
        return this.$store.state.productInfoModule.brandOptions;
      },
      productCertifications(): EnumInfo[] { // 建材认证
        return this.$store.state.productInfoModule.productCertifications;
      },
      fireRatings(): EnumInfo[] { // 防火等级列表
        return this.$store.state.productInfoModule.fireRatings;
      },
      isos(): EnumInfo[] { // 产品认证
        return this.$store.state.productInfoModule.isos;
      },
      productId(): string {
        return this.$store.state.productInfoModule.currentProductInfo.productId;
      },
    },
    methods: {
      querySearch(queryString, cb, item) {
        for (let i = 0; i < item.length; i += 1) {
          item[i].value = item[i].name;
        }
        const results = queryString ? item.filter(this.createFilter(queryString)) : item;
        cb(results);
      },
      createFilter(queryString) {
        return (restaurant) => (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      },
      handleSelect(item) {
        this.product.category = item.val;
      },
      del(idx) {
        this.info.list.splice(idx, 1);
      },
       // 选取文件提示
      beforeUpload(file) {
        const format = ['image/png', 'image/jpeg'];
        if (!format.includes(file.type)) {
          this.$message.warning('只支持.jpg .png格式');
          return false;
        }
        const isSize = new Promise(((resolve, reject) => {
          const width = 115;
          const height = 152;
          const url = window.URL || window.webkitURL;
          const img = new Image();
          img.onload = function () {
              const valid = img.width === width && img.height === height;
              if (valid) {
                resolve();
              } else {
                reject();
              }
          };
          img.src = url.createObjectURL(file);
        })).then(() => file, () => {
            this.$message.error('上传的图片尺寸必须是115*152');
            return Promise.reject();
        });
        return format.includes(file.type) && isSize;
      },
      async initInfo() {
        const opt = {
          pvId: this.$store.state.productInfoModule.currentProductInfo.pvId,
          productId: this.productId,
          step: 1,
        };
        const res = await api.getProductInfo(opt);
        if (!api.error(res)) {
          this.product = res.data.info;
          const opts = this.categoryOptions.find((i) => i.val === this.product.category);
          this.productCategory = opts ? opts.name : '';
          this.product.productId = res.data.productId;
          this.product.pvId = this.$store.state.productInfoModule.currentProductInfo.pvId;
          res.data.qualityItems.forEach((i) => {
            this.certAndRating[i.code.toLowerCase()] = (i.code === 'BM' || i.code === 'ISO') ? i.value.split(',') : i.value;
          });
          res.data.techDocs.forEach((i) => {
            this.info.list.push({
              docs: {
                fileId: i.fileId,
                name: i.name,
                size: i.size,
                cover: i.cover,
              },
              name: i.name,
            });
          });
        }
      },
      submitBaseInfo(form) {
        console.log(this.$route);
        const { path } = this.$route;
        (this.$refs[form] as any).validate(async (valid) => {
          if (valid) {
            const types: string[] = [];
            this.$store.state.productInfoModule.selectedClassify.forEach((element) => {
              types.push(element.type);
            });
            this.product.types = types;
            const opts = this.categoryOptions.find((i) => i.val === this.product.category);
            this.product.categoryTxt = opts ? opts.name : '';
            const techDocs = this.info.list.map((i) => i.docs);
            const option = {
              product: this.product,
              certAndRating: {
                bm: this.certAndRating.bm.toString(),
                fr: this.certAndRating.fr,
                iso: this.certAndRating.iso.toString(),
                sf: this.certAndRating.sf,
              },
              techDocs,
            };
            const res = await api.saveProductBaseInfo(option);
            if (!api.error(res)) {
              this.$store.commit('productInfoModule/currentProductInfo', {
                productId: res.data.productId,
                pvId: res.data.pvId,
                status: path === '/product/add' ? '0' : this.$store.state.productInfoModule.currentProductInfo.status,
                statusTxt: path === '/product/add' ? '草稿' : this.$store.state.productInfoModule.currentProductInfo.statusTxt,
              });
              this.product.productId = res.data.productId;
              this.product.pvId = res.data.pvId;
              this.$emit('saveInfo', true);
            } else {
              this.$message.error(res.error.message);
            }
          } else {
            this.$emit('saveInfo', false);
          }
        });
        this.$emit('endSave');
      },
      docsInfo(list) {
        this.info.list = list;
      },
      async handleAvatarSuccess(idx, response, file) { // 上传封面图
        if (response.success) {
          this.$set(this.info.list[idx], 'imageUrl', URL.createObjectURL(file.raw)); // 前台显示
          this.$set(this.info.list[idx].docs, 'cover', response.items[0].url); // 返回给后台的
        }
      },
    },
    async mounted() {
      await this.$store.dispatch('getToken');
      this.headers.Authorization = this.$store.state.fileToken;
      await this.$store.dispatch('productInfoModule/getCategoryOptions');
      this.$store.dispatch('productInfoModule/getBrandOptions', { MaxResultCount: 1000, SkipCount: 0 });
      if (this.productId) this.initInfo();
    },
  });
